import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

  <Layout title="Data Center & Cloud">
    <section>
      We know there are challenges to implement Hybrid or Private Cloud. Whether moving from public to private cloud or build your own private, or hybrid cloud
      solution, we’ll help you achieve your cloud vision. Our enterprise service delivery experience, coupled with industry leading technology partners, enables
      us to assess your workload and application environment and create a cloud strategy that’s right for your business.
      <br/>
      <br/>

      Quantic Cloud Services include:
      <br/>
      <br/>

      <ul>
        <li>Cloud Consulting Services – Get support for every phase of the private or hybrid cloud transition – strategy, analysis, design, and operations
          management.
        </li>
        <li>Cloud Readiness Assessment – Understand how business processes and technology maturity impact cloud strategies through analysis.</li>
        <li>Cloud Proof of Concept – Validate your use case, determine infrastructure readiness, identify integration requirements, and establish success
          criteria.
        </li>
        <li>Cloud Platform Alignment – Assess workload requirements to determine which cloud platform – public, private, or hybrid – is the optimal solution.
        </li>
        <li>Cloud Platform for Crypto Currency Mining: Design and Implement a Hosting solution for Bit miners with ability to scale up to 500 miners.</li>
        <li>Cloud Platform for Crypto Currency Mining: Design and Develop software and integration tools to connect with Cryptocurrency exchanges.</li>
      </ul>
    </section>
  </Layout>
);

export default Page;
